<!-- breadcrumb section start -->
<app-gym-nav></app-gym-nav>

<section class="" style="background-color: grey;" id="home"></section>
<section class="breadcrumb-section-main inner-2 breadcrumb-section-sm">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumb-contain ">
                        <div>
                            <h2 class="breadcrumb-txt">Login</h2>
                        </div>
                        <div>
                            <ul>
                                <li><a href="#">Login</a></li>
                                <li><a href="#"><i class="fa fa-angle-double-right"></i>Login</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>
<!-- section start -->
<section class="login-page section-b-space">
  <div class="container">
      <div class="row">
          <div class="col-lg-6">
              <h3>Login</h3>
              <div class="theme-card">
                  <form class="theme-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                      <div class="form-group">
                          <label for="email">Email</label>
                          <input type="email" class="form-control" formControlName="email" id="email" placeholder="Email" required>
                      </div>
                      <div class="form-group">
                          <label for="review">Password</label>
                          <input type="password" class="form-control" formControlName="password"  id="review" placeholder="Enter your password" required>
                      </div>
                      <div class="error-message" *ngIf="loginForm.invalid && submitted">
                        Invalid email or password.
                      </div>
                      <button type="submit" class="btn primary-btn btn-default radius-0 mt-2">Login</button>
                      <div class="error-message mt-4" *ngIf="error">
                        {{ error }}
                      </div>
                  </form>
              </div>
          </div>
          <div class="col-lg-6 right-login">
              <h3>Nuovo Cliente</h3>
              <div class="theme-card authentication-right">
                  <h6 class="title-font">Crea un account</h6>
                  <p>Crea un'account ti bastano pochi secondi !</p>
                  <a class="btn primary-btn btn-default radius-0" [routerLink]="['/signup']" >Crea</a>
              </div>
          </div>
      </div>
  </div>
</section>
<!--Section ends-->
