import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AboutMeComponent } from './layouts/about-me/about-me.component';
import { ContactComponent } from './layouts/contact/contact.component';
import { GymComponent } from './layouts/gym/gym.component';
import { NutrizioneComponent } from './layouts/nutrizione/nutrizione.component';
import { AllenamentoComponent } from './layouts/allenamento/allenamento.component';
import { VideoComponent } from './layouts/video/video.component';
import { CarrelloComponent } from './layouts/carrello/carrello.component';
import { LoginComponent } from './layouts/login/login.component';
import { SignUpComponent } from './layouts/sign-up/sign-up.component';
import { CheckoutComponent } from './layouts/checkout/checkout.component';
import { MenuLateraleComponent } from './layouts/menu-laterale/menu-laterale.component';
import { VideoCorsiComponent } from './layouts/video-corsi/video-corsi.component';
import { CorsiComponent } from './layouts/corsi/corsi.component';
import { LoginSiteComponent } from './layouts/login-site/login-site.component';
import { AuthGuard } from './shared/guard/auth.guard'
export const routes: Routes = [
  {
    path: '',
    redirectTo: '/gym',
    pathMatch: 'full',
  },
  {
    path: 'gym',
    component: GymComponent
  },
  {
    path: 'nutrizione',
    component: NutrizioneComponent
  },
  {
    path: 'allenamento',
    component: AllenamentoComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'about-me',
    component: AboutMeComponent
  },
  {
    path: 'landingpage',
    component: VideoComponent
  },
  {
    path: 'carrello',
    component: CarrelloComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logincorsi',
    component: LoginSiteComponent
  },
  {
    path: 'signup',
    component: SignUpComponent
  },
  {
    path: 'checkout',
    component: CheckoutComponent
  },
  { path: 'video/:id', component: VideoCorsiComponent }, //12
  { path: 'corsi', component: CorsiComponent, canActivate: [AuthGuard] },
  // { path: '', pathMatch: 'full', redirectTo: 'corsi' },

  // {
  //   path: 'video/:id',
  //   component: VideoCorsiComponent
  // },
  // {
  //   path: 'corsi',
  //   component: CorsiComponent,
  //   children: [
  //     { path: '', component: VideoCorsiComponent }, // Reindirizza '/corsi' a '/corsi/video/:id'
  //     { path: 'video/:id', component: VideoCorsiComponent }, // Rotte per video specifici
  //   ]
  // },


];

@NgModule({
//   imports: [RouterModule.forRoot(routes, {
//      preloadingStrategy: PreloadAllModules,
//     anchorScrolling: 'enabled',
//     scrollPositionRestoration: 'enabled',
//     useHash: false
// })],
imports: [RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules,
  anchorScrolling: 'enabled',
  scrollPositionRestoration: 'enabled',
  relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
